<template>
    <ProductResourceGallery v-editable="blok" :data="productResourceGalleryContent" />
</template>

<script setup lang="ts">
import ProductResourceGalleryType from '@/types/ProductResourceGalleryType';
import ProductResourceItemType from '@/types/ProductResourceItemType';
import ImageType from '@/types/ImageType';
import LinkType from '~/types/LinkType';
import ProductResourceGallery from '~/components/page-building/product-resource-gallery/ProductResourceGallery.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const resourceItems = props.blok.resource_items
    ? props.blok.resource_items.map((item: any) => {
          return {
              title: item.title,
              image: {
                  title: item.image.name,
                  alt: item.image.alt,
                  src: item.image.filename,
              } as ImageType,
              icon: item.icon
                  ? ({
                        title: item.icon.name,
                        alt: item.icon.alt,
                        src: item.icon.filename,
                    } as ImageType)
                  : null,
              description: item.description,
              tag: item.tag,
              link:
                  item.link?.length > 0
                      ? ({
                            label: item.link[0].label,
                            url: item.link[0].target.cached_url,
                            target: '',
                        } as LinkType)
                      : null,
              productType: item.product_type ? item.product_type : [],
              productCategory: item.product_category
                  ? item.product_category
                  : [],
              property: item.property ? item.property.split('\n') : [],
              material: item.material ? item.material : [],
              specialEffect: item.special_effect
                  ? item.special_effect.split('\n')
                  : [],
              featureOrAccessory: item.feature_or_accessory
                  ? item.feature_or_accessory.split('\n')
                  : [],
          } as ProductResourceItemType;
      })
    : [];

const productResourceGalleryContent = computed<ProductResourceGalleryType>(
    () => {
        return {
            title: props.blok.title,
            productTypeFilters: props.blok.product_type_filters
                ? props.blok.product_type_filters
                : [],
            productCategoryFilters: props.blok.product_category_filters
                ? props.blok.product_category_filters
                : [],
            propertyFilters:
                props.blok.property_filters && props.blok.property_filters != ''
                    ? props.blok.property_filters.split('\n')
                    : [],
            materialFilters: props.blok.material_filters
                ? props.blok.material_filters
                : [],
            specialEffectFilters:
                props.blok.special_effect_filters &&
                props.blok.special_effect_filters != ''
                    ? props.blok.special_effect_filters.split('\n')
                    : [],
            featureAndAccessoryFilters:
                props.blok.feature_and_accessory_filters &&
                props.blok.feature_and_accessory_filters != ''
                    ? props.blok.feature_and_accessory_filters.split('\n')
                    : [],
            resourceItems,
            backgroundColor: props.blok.background_color,
            nextBackgroundColor: props.nextBlock
                ? props.nextBlock.background_color
                : null,
            bottomAngle: props.blok.bottom_angle,
        };
    });
</script>
